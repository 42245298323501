.wrapper {
  /* padding: 4rem 2rem 2rem 2rem; */
  max-width: 960px !important;
  min-height: 441px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px 0 82px 0;
  padding: 40px;
}

.illustration {
  height: 250px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}

.illustration svg {
  height: 100%;
}

.blob {
  background: #ea8a8c;
  opacity: 0.3;
  position: absolute;
  top: 0;
  z-index: -1;
}

.date {
  position: relative;
  padding: 5px 12px;
}
.date::before {
  content: "";
  height: 100%;
  width: 100%;
  background: #ea8a8c;
  opacity: 0.2;
  top: 0;
  left: 0;
  position: absolute;
  z-index: -1;
  border-radius: 3px;
  transform: rotate(-1.4deg);
}

.half {
  width: 50%;
}

@media (max-width: 640px) {
  .half {
    width: 100%;
  }
}

.description {
  font-size: 16px;
  font-family: "PT Sans", sans-serif;
  color: rgba(0, 0, 0, 0.54);
  letter-spacing: 0.37px;
  line-height: 1.8;
  text-align: left;
}

.details {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  position: relative;
}

/* Misc Components */
.listWrapper {
  list-style: none;
  display: inline-flex;
  flex-wrap: wrap;
  padding: 0;
}

.listWrapper li {
  width: 20%;
  text-align: left;
  margin: 8px;
  display: flex;
  align-items: center;
  text-transform: capitalize;
}

.icon {
  margin-right: 16px;
}

.icon svg {
  max-width: 16px;
  max-height: 16px;
}

.card {
  padding: 16px 24px;
  width: 50%;
  border-radius: 4px;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
