.detail {
  margin-bottom: 16px;
  font-size: 14px;
  padding: 16px 24px;
  border-bottom: 1px solid grey;
}

.time {
  color: #a6aa9c;
  margin-bottom: 4px;
  font-weight: bold;
}

.text {
  color: #494949;
  font-size: 16px;
}

.open {
  opacity: 0;
  max-height: 0;
}
.detail:hover .open {
  padding-top: 20px;
  max-height: 300px;
  opacity: 1;
}
