.wrapper {
  background: #f2f2f2;
  padding: 0 0 100px 0;
}

.carousel {
  width: 100%;
  padding: 0;
}

.gallery {
  display: block;
  max-width: 960px;
  width: 100%;
  margin: 0 auto;
}

.details {
  max-width: 960px;
  margin: 0 auto;
}

.details ul {
  list-style: none;
  display: inline-flex;
  flex-wrap: wrap;
  padding: 0;
}

.item {
  text-align: left;
  display: flex;
  align-items: center;
  text-transform: capitalize;
  padding: 16px;
  width: 100%;
  font-size: 12px;
  min-height: 40px;
  border-radius: 5px;
  box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.11),
    0 5px 15px 0 rgba(0, 0, 0, 0.08);
}

.item:not(:nth-child(even)) {
  /* margin-right: 20px; */
}

.cta {
  justify-content: center;
  align-items: center;
}
