.scrolldown {
  cursor: pointer;
  width: 25px;
  position: absolute;
  bottom: 60px;
  display: block;
  height: 50px;
  webkit-border-radius: 13px;
  -moz-border-radius: 14px;
  border-radius: 14px;
  border: 2px solid #ea8a8c;
  left: 50%;
  margin-left: -14px;
  z-index: 200;
}
.scrolldown span {
  display: block;
  margin: 6px auto;
  width: 2px;
  height: 6px;
  border-radius: 4px;
  background: #ea8a8c;
  border: 2px solid transparent;
  -webkit-animation-duration: 1.6s;
  animation-duration: 1.6s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-name: scroll;
  animation-name: scroll;
}
@-webkit-keyframes scroll {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(25px);
    transform: translateY(25px);
  }
}
@keyframes scroll {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(25px);
    -ms-transform: translateY(25px);
    transform: translateY(25px);
  }
}

@media (max-width: 992px) {
  .scrolldown {
    bottom: 30px;
  }
}
