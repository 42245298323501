.wrapper {
  /* flex-direction: column; */
  padding: 16px;
  background: #222222;
}

.content {
  max-width: 960px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  color: #ffffff;
}

.content p {
  margin: 4px;
  font-size: 14px;
  text-align: center;
}

.column {
  width: 33.33%;
  padding: 0 24px;
}

.subfooter {
  width: 100%;
  font-size: 12px;
  opacity: 0.5;
  padding: 0 24px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo {
  width: 50px;
  opacity: 0.6;
}

.logo g {
  stroke: #ffffff;
}

.tbt {
  text-decoration: none;
  color: #ffffff;
}
