/* ----------------------------------------------
 * Generated by Animista on 2018-11-10 12:48:45
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation fade-in
 * ----------------------------------------
 */
@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.wrapper {
  opacity: 0;
  transition: all 300ms ease-in-out;
  transform: translate3d(0, 10px, 0);
  position: relative;
  /* padding: 24px 0px 0; */
}

.visible {
  opacity: 1;
  display: block;
  transform: translate3d(0, 0, 0);
  /* animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both; */
}
