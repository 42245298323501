.wrapper {
  background: #f3ecec;
  padding: 24px;
  position: relative;
}

.content {
  max-width: 960px;
  margin: 0 auto;
}

.form {
  display: flex;
}

@media (max-width: 640px) {
  .form {
    flex-direction: column;
  }
}

.column {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-content: stretch;
  /* padding: 0 12px; */
  margin-bottom: 12px;
}

.column:first-child {
  margin-right: 8px;
}
.column:last-child {
  margin-left: 8px;
}

@media (max-width: 640px) {
  .column {
    width: 100%;
  }

  .column:first-child {
    margin-right: 0px;
  }
  .column:last-child {
    margin-left: 0px;
  }
}

.column > *:not(:last-child) {
  margin-bottom: 12px;
}

.RegisterLayerTop {
  position: absolute;
  top: -80px;
  left: 0;
  width: 100%;
}

/* Form */

.radio {
  display: flex;
  align-items: center;
}

.radio > label {
  width: calc(50% - 8px);
  background: rgba(255, 255, 255, 0.8);
  border: 1px solid #eaddda;
  border-radius: 5px;
  padding: 16px;
  display: block;
}

.radio > label:not(:last-child) {
  margin: 0 16px 0 0;
}

input {
  height: 24px;
  appearance: none;
}

input[type="radio"] {
  margin: 2px 8px;
  height: auto;
  appearance: radio;
}

select {
  height: 48px;
  text-indent: 18px;
}

input,
select,
textarea {
  border: none;
  font-family: "Open Sans", sans-serif;
  border-radius: 4px;
  padding: 16px 24px;
  font-size: 14px;
  background: rgba(255, 255, 255, 0.8);
  border: 1px solid #eaddda;
  color: rgba(0, 0, 0, 0.54);
  transition: all 300ms cubic-bezier(0.075, 0.82, 0.165, 1);
}

input:focus,
select:focus,
textarea:focus {
  outline: none;
  background: rgba(255, 255, 255, 0.4);
  color: #462c34;
}

textarea {
  height: 100%;
}

button[type="submit"] {
  font-family: "Amatic SC", cursive;
  font-weight: 500;
  height: 100%;
  max-height: 50px;
  width: 100%;
  background-image: radial-gradient(
    circle farthest-corner at 10% 20%,
    #ea9085 0%,
    #d3857b 47.7%,
    #eb8173 92.3%
  );
  border: none;
  font-size: 21px;
  color: #ffffff;
}

button:focus {
  outline: none;
  box-shadow: none;
}

.success {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.success p.SuccessTitle {
  font-size: 42px;
  color: #ea9085;
  margin: 4px;
  font-family: "Amatic SC", cursive;
}
