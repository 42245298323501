.wrapper {
  padding: 50px 8px;
  min-height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 640px) {
  .wrapper {
    padding: 48px;
  }
}

.content {
  overflow: hidden;
}

.item,
.icon {
  display: flex;
  /* justify-content: center; */
  align-items: center;
}

.icon {
  margin-right: 44px;
}

/* PackageItem */
.item {
  min-height: 65px;
}
.item p {
  margin: 0;
}

.icon svg {
  max-width: 32px;
  max-height: 32px;
}

.icon svg path {
  fill: #ea9085;
}

.description span {
  font-size: 12px;
  display: block;
  opacity: 0.54;
}

.list {
  display: inline-flex;
  flex-wrap: wrap;
  max-width: 900px;
}

.list div {
  width: 50%;
  text-align: left;
  display: flex;
}

@media (max-width: 640px) {
  .list {
    display: flex;
    flex-direction: column;
  }

  .list > div {
    width: 100%;
  }
}
