@import url("https://fonts.googleapis.com/css?family=Amatic+SC|Open+Sans");
@import url("https://fonts.googleapis.com/css?family=IM+Fell+English");
@import url("https://fonts.googleapis.com/css?family=Lato:400,700");
@import "~react-responsive-carousel/lib/styles/carousel.min.css";

/* * {
  border: 1px solid red;
} */
body {
  font-size: 16px;
  font-family: "Open Sans", sans-serif;
  color: #63686e;
  box-sizing: border-box;
  background: inherit !important;
}

/* .undefined {
  border: 4px solid red;
} */

h1,
h2,
h3,
h4,
h5,
h6 {
  /* font-family: "IM Fell English", serif; */
  font-family: "Amatic SC", cursive;
}

::selection {
  background: #ea8a8a;
  color: #ffffff;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* ----------------------------------------------
 * Generated by Animista on 2018-11-10 12:48:45
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation fade-in
 * ----------------------------------------
 */
@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
/* ---- */
.list {
  list-style-type: none;
  display: flex;
  margin: 2rem 0 0 0;
  padding: 0;
}

.listItem {
  /* width: 9rem;
  height: 9rem; */
  display: block;
  cursor: pointer;
  position: relative;
}

.lisItemBlob::before {
  background: #ea8a8c;
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  z-index: 3;
  opacity: 0.7;
}

/* .listItem > div {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
} */

.listItem + .listItem {
  margin-left: 1.5rem;
}

.focusedItem {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  cursor: pointer;
  z-index: 2;
}

.focusedItem > div {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

code {
  color: #1b1b05;
  background-color: #fbf6de;
  border-radius: 2px;
  margin: 0 0.5rem;
}

/* Sticky Header */
.is-sticky {
  box-shadow: 1 3px 3px -2px rgba(0, 0, 0, 0.2), 0 3px 4px 0 rgba(0, 0, 0, 0.14),
    0 1px 8px 0 rgba(0, 0, 0, 0.12);
}

.is-sticky .brand {
  transform: scale(0.8);
  transition: all 375ms cubic-bezier(0.075, 0.82, 0.165, 1);
}
