.wrapper {
  padding: 48px 16px;
  background: #f7f7f7;
}

.content {
  max-width: 960px;
  margin: 0 auto;
}

/* layout */
.grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(14rem, 1fr));
  grid-template-rows: minmax(14rem, 1fr);
  grid-auto-rows: 1fr;
  grid-gap: 16px;
  grid-auto-flow: dense;
}

.grid-template-columns {
  grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
}

.grid-gap {
  grid-gap: 2.5rem;
}

/* styling */

.card--expanded {
  grid-column: span 2;
  grid-row: span 2;
}

@media (max-width: 640px) {
  .card--expanded {
    grid-column: span 1;
    grid-row: span 1;
  }
}

.expanded > div {
  transition: transform 375ms cubic-bezier(0.075, 0.82, 0.165, 1);
}

.card {
  cursor: pointer;
  background: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  border-radius: 8px;
  max-width: 100vw;
  box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.11),
    0 5px 15px 0 rgba(0, 0, 0, 0.08);

  /* background-image: linear-gradient(
    87.4deg,
    rgba(235, 156, 146, 0.05) 0%,
    rgba(255, 255, 255, 1) 25%,
    rgba(255, 255, 255, 1) 50%,
    rgba(255, 255, 255, 1) 75%,
    rgba(235, 156, 146, 0.05) 100%
  ); */
}

.card > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  max-width: 100vw;
}

.card__avatar {
  min-height: 6rem;
  min-width: 6rem;
  border-radius: 100%;
  background-color: hsla(0, 0%, 0%, 0.2);
  margin-bottom: 1rem;
  background-size: cover;
  box-shadow: inset 0 0px 10px -5px black;
}

.card__title {
  height: 1rem;
  width: 100%;
  font-weight: bold;
  margin-bottom: 1rem;
}

.card__description {
  height: 100%;
  width: 100%;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.4);
}

.card__website {
  text-align: left;
  width: 100%;
}

.card__website a {
  color: #ea8a8c;
  font-size: 12px;
  text-decoration: none;
}

.mb-4 {
  margin-bottom: 1rem;
}

.pt-4 {
  margin-top: 1rem;
}

.p-4 {
  padding: 1rem;
}

button {
  padding: 0.75rem;
  margin-right: 0.75rem;
  background-color: #191919;
  color: lightgray;
  border: 1px solid lightgray;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background-color: hsla(0, 0%, 83%, 0.05);
}
button:focus {
  box-shadow: 0 0 0 3px #7396e4;
  outline: none;
}

a {
  font-weight: bold;
  color: #7396e4;
}

code {
  color: #7396e4;
}

.trim {
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
