.button {
  font-family: "Lato", sans-serif;
  text-transform: uppercase;
  font-weight: bold;
  color: #ea8a8c;
  border-radius: 50px;
  border: 2px solid #ea8a8c;
  padding: 12px 24px;
  background: transparent;
  cursor: pointer;
  font-size: 14px;
  transition: all 375ms cubic-bezier(0.075, 0.82, 0.165, 1);
  display: block;
  white-space: nowrap;
  max-height: 50px;
  margin: auto;
}

.button a {
  text-decoration: none;
  color: inherit;
}

.button:hover {
  background: #ea8a8c;
  color: #fff;
}

.button:disabled {
  opacity: 0.5;
  pointer-events: none;
  cursor: progress;
}

.button:focus {
  outline: none;
  box-shadow: none;
}

.button.light {
  border-color: #ffffff;
  color: #ffffff;
}

.button.light:hover {
  border-color: #ea8a8c;
}
