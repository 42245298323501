.title {
  font-size: 2.5rem;
  position: relative;
  /* text-align: center; */
  font-weight: 400;
  color: #685454;
  margin: 0 0 24px 0;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.line {
  border-bottom: 2px solid #ea9085;
  width: 40px;
  padding: 8px;
  display: block;
}

.title::after {
  width: 3rem;
  height: 1px;
  background-color: grey;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: -80px;
}
