.wrapper {
  height: calc(100vh - 66px);
  width: 100vw;
  position: relative;
}

.slider {
  height: 100%;
}

.slider > div {
  height: 100%;
  background: #4f323b;
}

.slide {
  height: 100%;
}

.col {
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #ece2df;
}

.image {
  width: 100%;
  max-height: 100%;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  /* clip-path: circle(50% at 1% 50%); */
  object-fit: cover;
}

.quoteWrapper {
  background: #ece2df;
  flex-direction: column !important;
  display: flex;
  padding: 16px 24px;
}

.quote {
  font-family: "IM Fell English", serif;
  position: relative;
  width: 70%;
  text-align: left;
  font-size: 21px;
  letter-spacing: 0.3px;
  line-height: 1.8em;
  text-align: justify;
  color: #685454;
  /* animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both; */
  z-index: 5;
  display: flex;
  justify-content: center;
  align-content: center;
  padding: 24px;
}

.author {
  display: block;
  margin: 8px 0;
  font-size: 1.4rem !important;
}

.quote:before {
  content: "\201C";
  font-size: 390px;
  position: absolute;
  top: 121px;
  left: -42px;
  color: rgba(234, 138, 138, 0.08);
}

.quote span {
  font-size: 38px;
  color: #4f323b;
  font-family: "PF Serif", serif;
}

.SmallImage {
  border-width: 0px 12px 24px;
  border: none;
  /* clip-path: circle(84.6% at 50% 1%); */
  height: 80%;
  position: absolute;
  top: -159px;
  left: 0;
  margin: auto;
}

.SmallImage::before {
  content: "";
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
  background: linear-gradient(
    180deg,
    rgba(236, 226, 223, 0) 0%,
    rgba(236, 226, 223, 1) 70%
  );
}

.QuoteLayer {
  position: absolute;
  content: "";
  height: 100vh;
  right: 0;
  top: 0;
  /* opacity: 0.9; */
  z-index: 2;
}

.left:before {
  content: "";
  height: 100%;
  width: 100%;
  background: radial-gradient(73% 147%, #eadfdf 59%, #ece2df 100%),
    radial-gradient(
      91% 146%,
      rgba(255, 255, 255, 0.5) 47%,
      rgba(0, 0, 0, 0.5) 100%
    );
  background-blend-mode: screen;
  position: absolute;
  opacity: 0.2;
  mix-blend-mode: screen;
}

.left::after {
  border-right: 3px solid #ed9595;
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
  top: -130px;
  right: 0px;
}

.progressive,
.isLoading {
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both; */
}

.isLoading {
  filter: blur(1.5rem);
  background: #685454;
}

.typed {
  display: flex;
  flex-direction: column;
}

.typed span {
  font-size: 21px;
  letter-spacing: 11px;
  /* margin-left: 3%; */
  color: #4f323b;
  font-family: "IM Fell English", serif;
  font-weight: bold;
  display: block;
  opacity: 0.8;
}

.mobileImage {
  /* clip-path: circle(78.3% at 48% 5%); */
  position: absolute;
  top: 0;
  /* max-height: 400px; */
  z-index: 0;
}

.mobileImage img {
  height: 100vh;
}

.mobileImage::after {
  position: absolute;
  content: "";
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  background: rgba(243, 237, 237, 0.8);
  z-index: 1;
}
