.wrapper {
  width: 100%;
  background: #27194b;
  padding: 24px 16px;
  box-sizing: border-box;
}

.content {
  max-width: 960px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
}

@media (max-width: 900px) {
  .leftContent {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .leftContent h4 {
    align-items: center !important;
  }
}

.buttons {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 900px) {
  .buttons {
    flex-direction: column;
  }

  .buttons > button {
    width: 100%;
    margin: 0 0 16px 0 !important;
    max-width: 215px;
  }
}

.buttons > button:first-child {
  margin-right: 16px;
}

.Title {
  color: #fb929e;
}

.price {
  color: #ffffff;
  font-weight: bold;
  margin: 8px 0;
}

.description {
  font-size: 21px;
  color: #fb929e;
}

.currency {
  /* color: rgba(255, 255, 255, 0.5); */
}

.caption {
  display: block;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.5);
}

.moreInfo {
  margin-top: 16px;
  text-decoration: none;
  color: rgba(255, 255, 255, 0.5);
  font-weight: 200;
  font-size: 12px;
}
