/* Slider */
#accomodation .carousel .slider-wrapper .slide {
  max-height: 600px;
}

#accomodation .carousel .slider-wrapper .slide img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}

/* Thumbs */
#accomodation .carousel .thumbs-wrapper {
  margin: 0;
}

#accomodation .carousel .thumbs-wrapper .thumbs {
  padding: 0;
  margin: 12px;
}

#accomodation .carousel .thumbs-wrapper .thumbs li {
  height: 120px;
  width: 120px;
  object-fit: cover;
  border-color: transparent;
}

#accomodation .carousel .thumbs-wrapper .thumbs li img {
  height: 100%;
  object-fit: cover;
}

#accomodation .carousel .thumbs-wrapper .thumbs li.selected {
  border-color: #ea9085;
}
