.wrapper {
  display: flex;
  background: #f3eded;
  justify-content: center;
  align-items: center;
}

.map {
  border: 0 !important;
}

.details {
  padding: 24px 50px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
