.wrapper {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

.header {
  /* display: flex; */
  /* justify-content: center; */
  /* align-items: flex-start; */
  height: 67px;
  /* z-index: 10; */
}

.headerContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 24px;
  background: #ffffff;
}

.Hamburger {
  /* position: relative; */
  width: 50px;
  height: 50px;
  /* right: 36px; */
  /* top: 36px; */
  padding: 16px;
  /* fill: black; */
}

.content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.HeroLayer {
  position: absolute;
  bottom: -2px;
  z-index: 4;
}

.logo {
  max-height: 40px;
}

.brand {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "proxima-nova", sans-serif;
  letter-spacing: 2px;
  color: #333333;
  text-align: center;
  z-index: 1;
  text-transform: uppercase;
  font-size: 0.8rem;
}
