input[type="date"] {
  width: 100%;
  box-sizing: border-box;
  padding: 24px;
}

input[type="date"]::before {
  font-family: "Open Sans", sans-serif;
  color: rgba(0, 0, 0, 0.54);
  margin-right: 8px;
  content: attr(placeholder) ":  ";
}
input[type="date"]:focus::before {
  content: "" !important;
}
